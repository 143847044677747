export async function rudderInitialize() {
  window.rudderanalytics = await import("rudder-sdk-js");

  window.rudderanalytics.load(
    process.env.RUDDER_STACK_WRITE_KEY,
    process.env.RUDDER_STACK_DATA_PLANE_URL,
    {
      integrations: { All: true }, // load call options
    }
  );

  window.rudderanalytics.ready(() => {
    console.log("we are all set!!!");
  });
}
