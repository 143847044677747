import Head from "next/head";
import Link from "next/link";
import parseHtml, { domToReact } from "html-react-parser";
import get from "lodash/get";
import * as React from "react";
import { motion } from "framer-motion";

import { Block } from "baseui/block";

import { rudderInitialize } from "../rudderInitialize";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};

// Determines if URL is internal or external
function isUrlInternal(link) {
  if (
    !link ||
    link.indexOf(`https:`) === 0 ||
    link.indexOf(`#`) === 0 ||
    link.indexOf(`http`) === 0 ||
    link.indexOf(`://`) === 0
  ) {
    return false;
  }
  return true;
}

// Replaces DOM nodes with React components
function replace(node) {
  const attribs = node.attribs || {};

  // Replace links with Next links
  if (node.name === `a` && isUrlInternal(attribs.href)) {
    const { href, style, ...props } = attribs;
    if (props.class) {
      props.className = props.class;
      delete props.class;
    }
    if (!style) {
      return (
        <Link href={href}>
          <a {...props}>
            {!!node.children &&
              !!node.children.length &&
              domToReact(node.children, parseOptions)}
          </a>
        </Link>
      );
    }
    return (
      <Link href={href}>
        <a {...props} href={href} css={style}>
          {!!node.children &&
            !!node.children.length &&
            domToReact(node.children, parseOptions)}
        </a>
      </Link>
    );
  }

  // Make Google Fonts scripts work
  if (node.name === `script`) {
    let content = get(node, `children.0.data`, ``);
    console.log({ content });
    if (content && content.trim().indexOf(`WebFont.load(`) === 0) {
      content = `setTimeout(function(){${content}}, 1)`;
      return (
        <script
          {...attribs}
          dangerouslySetInnerHTML={{ __html: content }}
        ></script>
      );
    }
  }
}
const parseOptions = { replace };

const loader = () => {
  return (
    <Block
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
    >
      <div className="container-framer">
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          className="item-framer"
        >
          <motion.path
            d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 2, ease: "easeInOut" },
              fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            }}
          />
        </motion.svg>
      </div>
    </Block>
  );
};

export default function Home(props) {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    rudderInitialize();
  }, []);

  console.log("isLoaded", isLoaded);

  // add a loader here to show the user that the page is loading for 2 sec

  React.useEffect(() => {
    const script = document.createElement("script");
    // jquery is loaded here

    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.0/jquery.min.js";
    script.async = true;
    document.body.appendChild(script);
    // only after jquery is loaded, webflow js is loaded

    const script1 = document.createElement("script");
    script1.src =
      "https://uploads-ssl.webflow.com/62b93a2c7713d8f727a544df/js/webflow.88ab1c3d6.js";
    script1.async = true;
    document.body.appendChild(script1);

    // inject the google fonts css , inter here

    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    // use inter font here

    script1.onload = () => {
      setIsLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script1);
      document.head.removeChild(link);
    };
  }, []);

  return (
    <>
      <Head>{parseHtml(props.headContent, parseOptions)}</Head>
      <div
        style={{
          fontFamily: "Inter " + "sans-serif" + " !important",
          padding: "0px",
          margin: "0px",
        }}
      >
        {/* if isloaded is false then show loader or parseHtml */}
        {isLoaded ? parseHtml(props.bodyContent, parseOptions) : loader()}
      </div>
    </>
  );
}

export async function getStaticProps(ctx) {
  // Import modules in here that aren't needed in the component
  const cheerio = await import(`cheerio`);
  const axios = (await import(`axios`)).default;

  // Use path to determine Webflow path
  let url = get(ctx, `params.path`, []);
  url = url.join(`/`);
  if (url.charAt(0) !== `/`) {
    url = `/${url}`;
  }
  const fetchUrl = "https://monthlysubs.webflow.io/";

  // Fetch HTML
  let res = await axios(fetchUrl).catch((err) => {
    console.error(err);
  });
  const html = res.data;

  // Parse HTML with Cheerio
  const $ = cheerio.load(html);

  // Convert back to HTML strings
  const bodyContent = $(`body`).html();
  const headContent = $(`head`).html();

  // Send HTML to component via props
  return {
    props: {
      bodyContent,
      headContent,
    },
  };
}
